import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './MoviePlayer.css';
import MovieList from './MovieList';

// Function to shuffle the array and get the first `count` items
const getRandomMovies = (movies, count) => {
  // Shuffle the array
  const shuffled = movies.sort(() => 0.5 - Math.random());
  // Return the first `count` movies
  return shuffled.slice(0, count);
};

const MoviePlayer = ({ movies }) => {
  const { title } = useParams();
  const navigate = useNavigate();

  const handleSelectMovie = (movie) => {
    const encodedTitle = encodeURIComponent(movie.title);
    navigate(`/movie/${encodedTitle}`);
  };
  
  // Find the movie based on the URL parameter
  const selectedMovie = movies.find(movie => movie.title === decodeURIComponent(title));

  if (!selectedMovie) return <div>Loading...</div>;

  // Filter movies to only include those with the same serial
  const recommendedMovies = movies.filter(
    movie => movie.serial === selectedMovie.serial && movie.title !== selectedMovie.title
  );

  // Get 10 random movies from the filtered recommendations
  const randomMovies = getRandomMovies(recommendedMovies, 100);

  return (
    <div className="movie-player">
      <h4>{selectedMovie.title}</h4>
      <iframe src={selectedMovie.iframes[0].src} title={selectedMovie.title} allowFullScreen></iframe>

      <div id='desc'>
        {selectedMovie.description && `${selectedMovie.description}`}
        <br /><br />
        {selectedMovie.category && `Platform: ${selectedMovie.category}`}
        <br /><br />
        {selectedMovie.serial && `Show : ${selectedMovie.serial}`}
        <br /><br />
        {selectedMovie.entryDate && `Date : ${selectedMovie.entryDate}`}
      </div>

      <h3 className="recommended-videos-title">Recommended Videos</h3>
      <MovieList movies={randomMovies} onSelectMovie={handleSelectMovie} />
    </div>
  );
};

export default MoviePlayer;
