import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './MovieCard.css';

const MovieCard = ({ movie, onSelectMovie }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="movie-card">
      <Helmet>
        <title>{movie.serial} - {movie.title}</title>
        <meta name="description" content={`Watch ${movie.serial} - ${movie.title}. Enjoy this serial featuring ${movie.description}.`} />
        <meta name="keywords" content={`${movie.serial}, ${movie.title}, watch ${movie.serial}, ${movie.description}`} />
        <meta property="og:title" content={`${movie.serial} - ${movie.title}`} />
        <meta property="og:description" content={`Watch ${movie.serial} - ${movie.title}. Enjoy this serial featuring ${movie.description}.`} />
        <meta property="og:image" content={movie.image} />
        <meta property="og:url" content={`https://yourwebsite.com/movie/${encodeURIComponent(movie.title)}`} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${movie.serial} - ${movie.title}`} />
        <meta name="twitter:description" content={`Watch ${movie.serial} - ${movie.title}. Enjoy this serial featuring ${movie.description}.`} />
        <meta name="twitter:image" content={movie.image} />
      </Helmet>
      <div className="movie-thumbnail-container">
        <img 
          style={{ cursor: "pointer", height: "11rem", width: "20rem", borderRadius: "5px" }} 
          onClick={() => onSelectMovie(movie)}  
          src={movie.image} 
          alt={movie.title} 
          className="movie-card-thumbnail" 
        />
        <p className="movie-duration"><i className="fa fa-regular fa-clock"></i> {movie.duration}</p>
        <div className="hd-label"> HD</div>
      </div>
      <div className="movie-card-content">
        <h3>{movie.title}</h3>
        {/* <div className="movie-card-buttons">
          <button className="movie-button" onClick={() => onSelectMovie(movie)}>Watch</button>
          <button className="movie-button" onClick={() => window.open(movie.shortenUrl, '_blank')}>Download</button>
        </div> */}
      </div>
    </div>
  );
};

export default MovieCard;
