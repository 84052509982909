import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import MovieFolderList from './components/MovieFolderList';
import MoviePlayer from './components/MoviePlayer';
import Navbar from './components/Navbar';
import SearchBar from './components/SearchBar';
import moviesData from './data/data1';
import ReactPaginate from 'react-paginate';
import { Helmet } from 'react-helmet';
import './App.css';

const App = () => {
  // Function to get the first movie of each unique serial
  const getUniqueSerialMovies = (movies) => {
    const uniqueSerials = new Set();
    return movies.filter(movie => {
      if (!uniqueSerials.has(movie.serial)) {
        uniqueSerials.add(movie.serial);
        return true;
      }
      return false;
    });
  };

  const uniqueSerialMovies = getUniqueSerialMovies(moviesData);

  const [filteredMovies, setFilteredMovies] = useState(uniqueSerialMovies);
  const [currentPage, setCurrentPage] = useState(0);
  const moviesPerPage = 20;
  const navigate = useNavigate();
  const location = useLocation();

  // Scroll to the top whenever the route changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  const handleSelectMovie = (movie) => {
    const encodedTitle = encodeURIComponent(movie.title);
    navigate(`/movie/${encodedTitle}`);
  };

  const handleSearch = (query) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = uniqueSerialMovies.filter(movie =>
      movie.title.toLowerCase().includes(lowercasedQuery)
    );
    setFilteredMovies(filtered);
    setCurrentPage(0); // Reset to the first page on search
  };

  const handleHomeClick = () => {
    setCurrentPage(0); // Reset to the first page
    setFilteredMovies(uniqueSerialMovies); // Reset to the original unique serial movies
    navigate('/');
  };

  const totalPages = Math.ceil(filteredMovies.length / moviesPerPage);
  const currentMovies = filteredMovies.slice(
    currentPage * moviesPerPage,
    (currentPage + 1) * moviesPerPage
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  return (
    <div className="app">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Indian Serial Hub</title>
        <meta name="description" content="Discover and watch the latest Indian web series and serials. Enjoy popular shows and get personalized recommendations." />
        <meta name="keywords" content="Indian web series, Indian serials, latest Indian shows, popular web series, watch online Indian serials" />
        <meta name="author" content="Your Name" />
        <meta property="og:title" content="Indian Serial Hub" />
        <meta property="og:description" content="Discover and watch the latest Indian web series and serials. Enjoy popular shows and get personalized recommendations." />
        <meta property="og:image" content="%PUBLIC_URL%/og-image.png" />
        <meta property="og:url" content="https://serialmaza.site
" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Indian Serial Hub" />
        <meta name="twitter:description" content="Discover and watch the latest Indian web series and serials. Enjoy popular shows and get personalized recommendations." />
        <meta name="twitter:image" content="%PUBLIC_URL%/twitter-image.png" />
        <meta name="twitter:site" content="@yourtwitterhandle" />
      </Helmet>
      <Navbar onHomeClick={handleHomeClick} />
      <div className="content">
        <SearchBar onSearch={handleSearch} />
        <Routes>
          <Route
            path="/"
            element={<MovieFolderList movies={currentMovies} onSelectMovie={handleSelectMovie} />}
          />
          <Route
            path="/movie/:title"
            element={<MoviePlayer movies={moviesData} />}
          />
        </Routes>
        {location.pathname === '/' && (
          <div className="pagination-container">
            <ReactPaginate
              pageCount={totalPages}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item previous"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item next"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              forcePage={currentPage} // Ensure pagination component reflects the current page
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
